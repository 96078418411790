@tailwind base;
@tailwind components;
@tailwind utilities;

/** Colors from https://palx.jxnblk.com/2389c9 **/
/** WMATA Colors from https://coolors.co/44af69-fcab10-4679b0-faa6ff-fb3640 **/

:root {
  --bold: 500;
  --rad: 4px;

  --dark-blue: #103f5c;
  --blue: #2389c9;
  --orange: #c96323;
  --red: #c92336;
  --green: #36c923;
  --dark-green: #006236;

  --anchor-hover-color: #333;
  --shadow: #333;

  --wmata-blue: hsl(211, 43%, 48%);
  --dark-wmata-blue: hsl(211, 43%, 8%);
  --light-wmata-blue: hsl(211, 43%, 88%);
}

::-webkit-scrollbar {
  width: 0;
}

html,
body {
  font-family: Arial, Helvetica, sans-serif;
  @apply text-slate-900;
}

a {
  @apply text-blue-600;
  text-decoration: underline;
}

a:hover {
  @apply text-blue-800;
}
